import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import FilterTable from '../../../components/FilterTable'
import { Link, useParams } from 'react-router-dom'
import DeleteLandOwner from '../../../APIs/Owner/DeleteLandOwner'
import LandOwnerPopUp from './LandOwnerPopUp'
import Summerybox from '../../../components/Summerybox'
import GetLandOwnerByLandId from '../../../APIs/Owner/GetLandOwnerByLandId'
import GetLandOwnedBy from '../../../APIs/Investor/GetLandOwnedBy'

export default function SecondLandInvestorShow() {
    let id = useParams()
    let [investorList, setInvestorList] = useState([])
    let [showModal, setShowModal] = useState(0)
    let [editData, setEditData] = useState([])
    let [projectOverviewData, setProjectOverviewData] = useState([])
    let [projectName, setProjectName] = useState("")
    let getData = async (id) => {
        setProjectName(JSON.parse(window.sessionStorage.getItem("ProjectData")).projectName)
        let resp = await GetLandOwnedBy(id)
        if (resp.status === 1) {
            setInvestorList(resp.responseValue)
            setProjectOverviewData(resp.responseValue.portfolioOverview)
        }
    }
    let deleteLand = async (ids) => {
        let resp = await DeleteLandOwner(ids)
        if (resp.status === 1) {
            getData(id.id)

        }
        else {

        }
    }
    useEffect(() => {
        if (id.id !== "null") {
            console.log("idddd", id)
            getData(id.id)
        }
    }, [])
    return (
        <>
            <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
                {/* <PageHeading pagename="Expenses Overview" /> */}
                <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3 pb-3'>
                    <PageHeading pagename={"Project "+projectName} />
                    {/* <button className="btn btn-primary" type="button" class="btn btn-primary" onClick={() => { setShowModal(1) }}>+ Add Expenses</button> */}
                </div>
                <div className='expenses-conatiner' style={{ columnGap: "25px" }}>




                    <Summerybox color="#FE996C" title="Total Amount" money={projectOverviewData && projectOverviewData.amountTobePaid} subheading="" />
                    <Summerybox color="#006CFF" title="Pending Amount" money={projectOverviewData && (projectOverviewData.pendingAmount)} subheading="" />
                    <Summerybox color="#03D4C6" title="Given Amount" money={projectOverviewData && projectOverviewData.givenAmount} subheading="" />
                    <Summerybox color="cyan" title="Processing Amount" money={projectOverviewData && projectOverviewData.processingAmount} subheading="" />
                </div>

            </div>
            <div className='container-fluid landformconatiner p-4 mt-3'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Land Owners" />
                    <FilterTable />
                </div>
                <div className='pt-4'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                <th>S.No.</th>
                                <th scope="col">Investor Name</th>
                                <th scope="col">Khasra</th>
                                <th scope="col">Percentage %</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Paid Amount</th>
                                <th scope="col">Pending Amount</th>
                                <th scope="col">Payment Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                investorList.transactions && investorList.transactions.map((val, ind) => {
                                    return (
                                        <tr >
                                            <td scope="row">{ind + 1}</td>
                                            <td>{val.name}</td>
                                            <td>{val.khasraNo}</td>
                                            <td>{val.percentage}%</td>
                                            <td>{val.amountToBePaid}</td>
                                            <td>{val.paidAmount}</td>
                                            <td>{val.pendingAmount}</td>
                                            <td>{val.paymentStatus}</td>
                                            <td>
                                                <div class="dropdown dropend">
                                                    {/* <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Dropdown button
                                                    </button> */}
                                                    <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mr-3">
                                                        <Link to={`/investorportfoliooverview/Project/${val.payeeType}/${val.referenceId}/${val.paidTo}/${val.paidToId}/1/1/${projectName}`}><li><span className="dropdown-item pointer" >View Transaction</span></li></Link>
                                                        {/* <li><span className="dropdown-item pointer" onClick={()=>{setEditData(val); setShowModal(1)}}>Edit</span></li>
                                                        <li><span className="dropdown-item pointer" onClick={() => { deleteLand(val.id) }}>Delete</span></li> */}
                                                        {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </table>
                </div>


            </div>

            {
                showModal === 1 ?
                    <LandOwnerPopUp showModal={showModal} closeModal={setShowModal} editData={editData} getData={() => { getData(id.id) }} />
                    :
                    ""
            }
        </>
    )
}
